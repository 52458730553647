<template>
	<div v-if="remoteCalledStream && remoteCalledStream.stream">
		<div style="display: flex; flex-direction: column">
			<video
				:srcObject.prop="remoteCalledStream.stream"
				id="testsocketID"
				autoplay
				playsinline
				webkit-playsinline
				style="width: 200px; height: 100%; padding: 3px; max-height: 250px; background-color: #b4b4b4;"
				class="video-element-sosclick-stream">
			</video>
		</div>
		<div class="mt-0">
			<CButton
				color="primary"
				class="w-100 custom-button-multiple-videocall d-flex justify-content-around align-items-center py-2"
				variant="outline"
				style="flex: 1;"
				@click="startVideoCall()"
			>
				<CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
			</CButton>
			<CButtonClose style="font-size: 40px;position: absolute;top: 10px;right: 10px;font-weight: 400;z-index: 9999;" @click="closePreview()"/>
		</div>
	</div>
	<div v-else>
		<div class="d-flex justify-content-center align-items-center flex-column h-100 px-3 py-2">
			<CButtonClose style="font-size: 20px;position: absolute;top: 2px;right: 10px;font-weight: 400;z-index: 9999;" @click="closePreview()"/>
			<CSpinner
				style="width:2rem;height:2rem;"
				color="#3c4b64"
				grow
			/>
			Esperando video del usuario ({{timerConnectionInterval}})
		</div>
	</div>
</template>

<script>
export default {
  name: 'VideoElementMultiple',
	props: {
		remoteCalledStream: {
			type: Object
		},
		loadingRemote: {
			type: Boolean,
			default: true
		},
		timerConnectionInterval: {
			type: Number,
			default: 20
		},
		closePreview: {
			type: Function,
			required: true
		},
		startVideoCall: {
			type: Function,
			required: true
		},
	},
  data(){
    return {
      componentStatus: false
    }
  },
  mounted(){
  },
  methods: {
    setDateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    toggleStatus(){
      this.componentStatus = !this.componentStatus
    }
  }
}
</script>

<style scoped>
	.custom-button-multiple-videocall {
			border-color: #CBCBCB;
			color: #666666;
			height: 45px;
	}
	.custom-button-multiple-videocall:hover{
		color: white;
	}
</style>