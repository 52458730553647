<template>
  <div v-if="trackingInfo && $store.state.trackingInBigMap && $store.state.trackingInBigMap.status">
    <div
        v-for="(route, index) in $store.state.trackingInBigMap.routes"
        :key="'routes' + index">
      <GmapMarker
        :key="'trakingB' + trackingInfo.deviceID + index"
        :ref="'trakingB' + trackingInfo.deviceID + index"
        :visible="route && route.length > 0 ? true:false"
        :position="route[route.length-1]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }}"
        :zIndex="3"
      />
      <GmapMarker
        :key="'trakingA' + trackingInfo.deviceID"
        :ref="'trakingA' + trackingInfo.deviceID"
        :visible="route && route.length > 0 ? true:false"
        :position="route[0]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }}"
        :zIndex="2"
      />
      <InfoPointTracking v-for="(point, indexB) in route" :point="point" v-if="route && route.length > 0 && indexB%5 === 0"/>
      <GmapPolyline
        :key="'route' + index"
        :options="{
          strokeWeight: 5,
          strokeColor: '#d0282e',
          strokeOpacity: 0.4
        }"
        :path="route"  
      />
    </div>
    <div v-if="$store.state.trackingInBigMap.showActualRoute && $store.state.trackingInBigMap && $store.state.trackingInBigMap.actualRoute.length > 0">
      <GmapMarker
        :key="'trakingActualB' + trackingInfo.deviceID"
        :ref="'trakingActualB' + trackingInfo.deviceID"
        :position="$store.state.trackingInBigMap.actualRoute[$store.state.trackingInBigMap.actualRoute.length-1]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../../../assets/imgs/pin4.png'), scaledSize: { width: 32, height: 44 }}"
        :zIndex="3"
      />
      <GmapMarker
        :key="'trakingActualA' + trackingInfo.deviceID"
        :ref="'trakingActualA' + trackingInfo.deviceID"
        :position="$store.state.trackingInBigMap.actualRoute[0]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../../../assets/imgs/pin5.png'), scaledSize: { width: 32, height: 44 }}"
        :zIndex="2"
      />
      <InfoPointTracking
        v-for="(point, indexB) in $store.state.trackingInBigMap.actualRoute"
        :point="point"
        v-if="$store.state.trackingInBigMap.actualRoute && $store.state.trackingInBigMap.actualRoute.length > 0 && indexB%5 === 0"/>
      <GmapPolyline
        key="route"
        :options="{
          strokeWeight: 5,
          strokeColor: 'green',
          strokeOpacity: 0.4
        }"
        :path="$store.state.trackingInBigMap.actualRoute"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as firebase from 'firebase/app'
import 'firebase/database'
import InfoPointTracking from '../InfoWindow/InfoPointTracking'

export default {
  name: 'MapSoloTracking',
  components: {InfoPointTracking},
  computed: {
    ...mapGetters(["trackingInBigMap"])
  },
  data(){
    return {
      cuadrante: this.$store.state.userData.info.cuadrante_id,
    	trackingInfo: null,
    	position: null,
      deviceStatus: 9,
      route: [],
      mapCenter: { lat: -33.4373063, lng: -70.634297 }
    }
  },
  mounted(){
    this.$events.listen('centerMapSoloTracking', (eventData) => {
      const center = {
        center: this.getCenter(),
        zoom: 18
      }
      this.$store.commit('setMapCenter', center)
    })
  },
  watch:{
    trackingInBigMap: function(data){
      if(data && data.status){
        this.trackingInfo = data.trackingInfo
      	this.createListenners(data.trackingInfo)
      }
      else{
      	this.removeListenners(this.trackingInfo)
      }
    }
  },
  methods: {
    getCenter(){
      if(this.$store.state.trackingInBigMap.actualRoute.length > 0){
        return this.$store.state.trackingInBigMap.actualRoute[this.$store.state.trackingInBigMap.actualRoute.length-1]
      }
      else if(this.$store.state.trackingInBigMap.routes.length > 0){
        return this.$store.state.trackingInBigMap.routes[0][this.$store.state.trackingInBigMap.routes[0].length-1]
      }
      return this.mapCenter
    },
  	closeSoloTracking(){
    	this.$store.commit("setTrackingInBigMap", null)
  	},
    createListenners(trackingInfo){
      const self = this
      const path = 'SOSclick/tracking/' + this.cuadrante + '/' + trackingInfo.id_usuario + '/' + trackingInfo.deviceID
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_added", function(snapshot) {
        let datos = snapshot.val()
        if(snapshot.key === 'route'){ self.actualRoute = datos }
      })
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_changed", function(snapshot) {
        let datos = snapshot.val()
        if(snapshot.key === 'route'){ self.actualRoute = datos }
      })
    },
    removeListenners(trackingInfo){
      const path = 'SOSclick/tracking/' + this.cuadrante + '/' + trackingInfo.id_usuario + '/' + trackingInfo.deviceID
      firebase.database(this.$store.state.trackingDB).ref(path).off("child_added")
      firebase.database(this.$store.state.trackingDB).ref(path).off("child_changed")
    	this.trackingInfo = null
    }
  }
}
</script>