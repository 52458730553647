<template>
          <GmapInfoWindow v-if="device" style="overflow: visible">
        <CRow style="width: 550px" v-if="!currentMarker">
		    <CCol col="12" xl="12" class="text-center mt-2">
	        <CSpinner
	          style="width:20px;height:20px;"
	          color="primary"
	        />
	        <p>Cargando información</p>
		    </CCol>
      </CRow>

            <div v-else class="FixedCard" style="position: relative">
              <p
              @click="closeInfo"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="currentMarker?.avatarImg != null
                      ? currentMarker?.avatarImg
                      : require('../../../assets/icons/user.svg')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item text-primary">
                    {{ currentMarker?.name }}
                  </h4>
                  <p v-if="device?.deviceID" class="FixedCard__Header__Data__Item">
                    <font-awesome-icon icon="calendar" style="margin-right: 10px;"/>Actualizado: {{ date }} {{time}}
                  </p>
                  <p v-else class="FixedCard__Header__Data__Item">
                    <font-awesome-icon icon="calendar" style="margin-right: 10px;"/>Actualizado: {{device.created_at}}
                  </p>

                  <p class="FixedCard__Header__Data__Item">
                    <font-awesome-icon icon="user" style="margin-right: 10px;"/>{{ currentMarker?.user?.name }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    <img style="width: 13px; margin-right: 8px;" src="../../../assets/imgicons/ICONOS SOSRecurso 22@2x.png" alt="">{{ currentMarker?.user?.email }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    <font-awesome-icon icon="phone" style="margin-right: 10px;"/>{{ currentMarker?.phone }}
                  </p>
                </div>
              </div>
              <div class="custom-title-info-window">
            <h6 style="font-size: 18px;" class="text-primary m-0">Acciones</h6>
            <hr/>
          </div>
        <CRow>
<!--         <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall(true, true, true)"
          >
            <CIcon name="silentMonitor" class="icons-svg outline"/> Monitoreo Silencioso
          </CButton>
        </CCol> -->
<!--           <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="!devices.length"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall()">
            <CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
          </CButton>
        </CCol> -->
<!--                   <CCol col="4" xl="4" class="p-1">
          <CButtonGroup v-show="tracking">
            <CButton              
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="startSoloTracking()"
            >
              <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/> Visualizar Ubicación
            </CButton>
            <CButton
              :disabled="deactivatingTracking"
              v-c-tooltip="'Desactivar Tracking'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="deactivateTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <div v-if="!deactivatingTracking">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.2rem!important;height: 1.2rem!important;"/>
                <font-awesome-icon icon="slash" style="font-size: 12px;position: absolute;left: 0;top: 0;bottom: 0;right: 0;margin: auto;" />
              </div>
              <div v-else>
                <CSpinner style="width:20px;height:20px;" color="primary" />
              </div>
            </CButton>
          </CButtonGroup>
          <CButtonGroup v-show="!tracking">
            <CButton
              :disabled="activatingTracking"
              color="primary"
              class="w-100 custom-button-info-content d-flex flex-column justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="activateTracking()"
            >
              <div v-if="!activatingTracking" class="w-100 d-flex justify-content-around align-items-center">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/>
                <p class="m-0">Activar Tracking</p>
              </div>
              <div v-else class="w-100 d-flex justify-content-around align-items-center">
                <CSpinner style="width:20px;height:20px;" color="primary" />
                <p class="m-0">Activando Tracking</p>
              </div>
              <p class="m-0" style="font-size: 10px;">{{trackingActivationMessage}}</p>
            </CButton>
            <CButton
              v-c-tooltip="'Consultar histórico'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="startSoloTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <font-awesome-icon icon="history" style="font-size: 20px;" />
            </CButton>
          </CButtonGroup>
        </CCol> -->
<!--         <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-3"
            :class="this.showNoti ? 'text-white':''"
            :variant="this.showNoti ? '':'outline'"
            style="flex: 1;"
            @click="() => this.showNoti = !this.showNoti"
          >
            <CIcon :name="this.showNoti ? 'cilX':'notification'" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> {{this.showNoti ? 'Cancelar':'Crear'}} Notificación
          </CButton>
		    </CCol> -->
          <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="$store.state.toCallData ? true:false"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="$store.state.toCallData ? endCall() : call()"
          >
            <CIcon name="call" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> {{$store.state.toCallData ? 'Terminar LLamada' : 'Llamar por Teléfono'}}
          </CButton>
        </CCol>

<!--         <CDropdown
        v-if="type == 'address'"
        placement="right"
        :disabled="!currentMarker.dispositivos.length"
    color="primary"
    toggler-text="Video LLamada" 
    class="m-2"
  >
  <div style="height: 200px; overflow-y: scroll; max-width: 500px;">
    <CDropdownItem @click="startVideoCall(item.device_id)" v-for="(item, index) in currentMarker.dispositivos" :key="'routes' + index">{{item.device_name}}</CDropdownItem>
  </div>
  </CDropdown> -->
        </CRow>
<!--               <CRow class="mt-2 pt-2" style="border-top: 1px solid #f2f2f2" v-show="showNoti">
        <CCol col="12" xl="12" class="text-center">
          <CInput
            placeholder="Título"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionTitle'
          />
          <CInput
            placeholder="Descripción"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionDescripcion'
          />
          <CAlert v-for="(toast,index) in toasts" :key="index" :color="toast.color" closeButton :show.sync="currentAlertCounter">
            {{toast.message}}
          </CAlert>
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="success"
            class="w-100 custom-button-info-content success d-flex justify-content-around align-items-center py-2"
            style="flex: 1;"
            @click="() => this.sendNotification()"
          >
            <CIcon name="send" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> Enviar Notificación
          </CButton>
        </CCol>        
      </CRow> -->
            </div>
          </GmapInfoWindow>
</template>

<script>
import { getButtonData } from '../../../api/buttons'
import moment from 'moment'

export default {
    props: ['device', 'closeInfo'],
    data () {
        return {
            currentMarker: null,
            //notificación
            showNoti: false,
            notificacionTitle: '',
            notificacionDescripcion: '',
            toasts: [],
            currentAlertCounter: 4,
          }
    },
    computed: {
        date() {
            return moment(this.device?.date, 'DDMMYY').format('DD/MM/YYYY')
        },
        time() {
          const momentTime = moment(this.device?.time, 'HHmmss');
          return momentTime.format('HH:mm')
        }
    },
    methods: {
        getButtonData() {
            const data = {button_id: this.device?.deviceID ? this.device?.deviceID : this.device?.button_id }
            getButtonData(data).then(res => {
                this.currentMarker = res.data
            })
        },
        close() {
            this.closeButtonInfo()
        },
        async startVideoCall(fullView = true, hideVideo = false, silent = true){
        this.$store.commit("setVideoCallData", {
            status: true,
            alertID: null,
            geoUserAlert: null,
            fullView,
            deviceID: this.type == 'multipleDevices' ? this.selectedDevice : this.device_id,
            calledUserID: this.currentMarker.id,
            socketUrl: null,
            socketRoom: null,
            videocallID: null,
            hideVideo,
            silent
        })
  	},
    call(){
      const callData = {
        phoneToCall: this.currentMarker?.phone,
        name: this.currentMarker?.name
      }
      
      this.$store.commit("setCallData", callData)
    },
    endCall(){
        this.$store.commit("setCallData", null)
    },
    sendNotification(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(this.notificacionTitle === '' || this.notificacionDescripcion === ''){
        self.toasts.push({
          title: 'Error',
          message: 'Por favor completa todos los campos antes de enviar la notificación.',
          color: 'warning'
        })
      }
      else{
        const deviceID =  this.type == 'multipleDevices' ? this.selectedDevice : this.device_id
        sendCustomNotification(deviceID, this.notificacionTitle, this.notificacionDescripcion)
          .then(function(resp){
            self.toasts.push({
              title: 'Notificación enviada',
              message: 'Se ha enviado la notificación al usuario.',
              color: 'success'
            })
            setTimeout(function() {
              self.showNoti = false
            }, 3000);
          })
          .catch(function(error){
            console.log(error)
            self.toasts.push({
              title: 'Error',
              message: 'Hubo un error al enviar la notificación, por favor inténtalo nuevamente.',
              color: 'danger'
            })
          })
      }
    },
    },
    filters: {
      filterDate: function (date) {
        return moment(date).format("DD-MM-YYYY HH:mm");
      },
    },
    mounted() {
        this.getButtonData()
    },
}
</script>

<style lang="scss" scoped>
.FixedCard {
  width: 648px;
  padding: 20px;
  &__Header {
    display: flex;
    align-items: center;

    gap: 32px;
    padding-bottom: 16px;
    &__Img {
      border: solid 2px #cccccc;
      width: 100px;
      border-radius: 10px;
      object-fit: cover;
      max-height: 100px;
      background-color: #e6ecff;
    }
    &__Data {
    display: flex;
    flex-direction: column;
    gap: 2px;
      &__Item {
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }
  &__Actions {
    margin-top: 16px;
    background-color: #F7F7F7;
    &__Title {
      display: flex;
      align-items: center;
    }
    &__Buttons {
      display: flex;
      &__Button {
        border: solid 1px #cccccc;
        border-radius: 8px;
        cursor: pointer;
        padding: 16px 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        &:hover {
          text-decoration: none;
          background-color: #e7e3e3;
        }
      }
    }
  }
}
.dropdown-menu .show {
    overflow: visible scroll;
    z-index: 99999;
    height: 200px;

}
</style>