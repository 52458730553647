var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trackingInfo && _vm.$store.state.trackingInBigMap && _vm.$store.state.trackingInBigMap.status)?_c('div',[_vm._l((_vm.$store.state.trackingInBigMap.routes),function(route,index){return _c('div',{key:'routes' + index},[_c('GmapMarker',{key:'trakingB' + _vm.trackingInfo.deviceID + index,ref:'trakingB' + _vm.trackingInfo.deviceID + index,refInFor:true,attrs:{"visible":route && route.length > 0 ? true:false,"position":route[route.length-1],"clickable":true,"customInfo":"Work","icon":{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }},"zIndex":3}}),_c('GmapMarker',{key:'trakingA' + _vm.trackingInfo.deviceID,ref:'trakingA' + _vm.trackingInfo.deviceID,refInFor:true,attrs:{"visible":route && route.length > 0 ? true:false,"position":route[0],"clickable":true,"customInfo":"Work","icon":{url: require('../../../assets/imgs/pin1.png'), scaledSize: { width: 32, height: 44 }},"zIndex":2}}),_vm._l((route),function(point,indexB){return (route && route.length > 0 && indexB%5 === 0)?_c('InfoPointTracking',{attrs:{"point":point}}):_vm._e()}),_c('GmapPolyline',{key:'route' + index,attrs:{"options":{
        strokeWeight: 5,
        strokeColor: '#d0282e',
        strokeOpacity: 0.4
      },"path":route}})],2)}),(_vm.$store.state.trackingInBigMap.showActualRoute && _vm.$store.state.trackingInBigMap && _vm.$store.state.trackingInBigMap.actualRoute.length > 0)?_c('div',[_c('GmapMarker',{key:'trakingActualB' + _vm.trackingInfo.deviceID,ref:'trakingActualB' + _vm.trackingInfo.deviceID,attrs:{"position":_vm.$store.state.trackingInBigMap.actualRoute[_vm.$store.state.trackingInBigMap.actualRoute.length-1],"clickable":true,"customInfo":"Work","icon":{url: require('../../../assets/imgs/pin4.png'), scaledSize: { width: 32, height: 44 }},"zIndex":3}}),_c('GmapMarker',{key:'trakingActualA' + _vm.trackingInfo.deviceID,ref:'trakingActualA' + _vm.trackingInfo.deviceID,attrs:{"position":_vm.$store.state.trackingInBigMap.actualRoute[0],"clickable":true,"customInfo":"Work","icon":{url: require('../../../assets/imgs/pin5.png'), scaledSize: { width: 32, height: 44 }},"zIndex":2}}),_vm._l((_vm.$store.state.trackingInBigMap.actualRoute),function(point,indexB){return (_vm.$store.state.trackingInBigMap.actualRoute && _vm.$store.state.trackingInBigMap.actualRoute.length > 0 && indexB%5 === 0)?_c('InfoPointTracking',{attrs:{"point":point}}):_vm._e()}),_c('GmapPolyline',{key:"route",attrs:{"options":{
        strokeWeight: 5,
        strokeColor: 'green',
        strokeOpacity: 0.4
      },"path":_vm.$store.state.trackingInBigMap.actualRoute}})],2):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }