<template>
  <div v-if="cameraInfo">
    <CModal
      title="Camara"
      color="primary"
      :show.sync="showModal"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Camara</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow style="height: 80vh;max-height: 660px;">
        <CCol col="9" xl="9">
					<CameraView style="height: 80vh;max-widht: 100%;max-height: 660px;"
						:cameraData="cameraInfo"
						:ptzWithKeys="true"
						:showCameraViewComponent="showModal"/>
        </CCol>
        <CCol col="3" xl="3">
					<GmapMap
						v-if="this.cameraInfo"
						:center="this.cameraInfo.position"
						:zoom="12"
						class="map-container"
						style="max-height: 250px; height: 100%">
							<GmapMarker
								:position="this.cameraInfo.position"
								:icon="{url: require('../../../assets/imgicons/ICONOS SOSRecurso 1-2@2x.png'), scaledSize: { width: 28, height: 35 }}"
							/>
					</GmapMap>
					<div>
						<div class="border-primary px-0 rounded my-2">
							<div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
								<p class="mb-0 ">Datos Cámara</p>
							</div>
						</div>
						<h6>Dirección</h6>
						<p>{{this.cameraInfo.address}}</p>
					</div>
					<div>
						<div class="border-primary px-0 rounded my-2">
							<div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
								<p class="mb-0 ">Datos Usuario</p>
							</div>
						</div>
						<p class="mb-0"><b>Nombre: </b>{{this.cameraInfo.user.name}}</p>
						<p class="mb-0"><b>Email: </b>{{this.cameraInfo.user.email}}</p>
						<p class="mb-0"><b>Teléfono: </b>{{this.cameraInfo.user.info.telefono}}</p>
						<p class="mb-0"><b>Dirección: </b>{{this.cameraInfo.user.info.direccion}}</p>
					</div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import CameraView from './CameraView'

export default {
  name: 'ModalCameraViewer',
  props: ['cameraInfo', 'showModal', 'closeModal'],
	components: {
		CameraView
  },
  data () {
    return {
			videoOptions: null
    }
  },
  mounted(){
  },
  methods: {
  }
}
</script>

<style type="text/css" media="screen">
  .select-cierre-tipo .vs__search::placeholder,
  .select-cierre-tipo .vs__dropdown-toggle,
  .select-cierre-tipo .vs__dropdown-menu,
  .select-cierre-tipo .vs__open-indicator{
    color: #d0282e;
    border-color: #d0282e;
  }
</style>