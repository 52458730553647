<template>
  <div>
    <GmapMarker
      :key="'traking'+index"
      :ref="'traking'+index"
      :visible="$store.state.showTracking"
      v-for="(m, index) in tracking"
      :position="{lat: m.position.latitude, lng: m.position.longitude}"
      :clickable="true"
      customInfo="Work"
      :icon="{url: require('../../../assets/imgicons/ICONOS SOSRecurso 8.png'), scaledSize: { width: 32, height: 40 }}"
      @click="botonClick(m)"
    />
  </div>
</template>

<script>

import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'TrackingMarkers',
  data(){
    return {
      cuadrante: this.$store.state.userData.info.cuadrante_id,
      tracking: []
    }
  },
  mounted(){
    this.createListenners()
  },
  methods: {    
    createListenners(){
      const self = this
      const path = 'SOSclick/tracking/' + this.cuadrante
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_added", function(snapshot) {        
        let datos = snapshot.val()     
        Object.keys(datos).map(function(key, index) {
          const data = datos[key]
          if(data.position && data.position.latitude && data.position.longitude){
            const found = self.tracking.findIndex(function(item, index){
              return item.deviceID === data.deviceID
            })
            if(found !== -1){
              self.tracking[found] = data
              self.tracking.sort()
            }
            else{
              self.tracking.push(data)
            }
          }
        })
      })
      firebase.database(this.$store.state.trackingDB).ref(path).on("child_changed", function(snapshot) {       
        let datos = snapshot.val()  
        Object.keys(datos).map(function(key, index) {
          const data = datos[key]
          if(data.position && data.position.latitude && data.position.longitude){
            const found = self.tracking.findIndex(function(item, index){
              return item.deviceID === data.deviceID
            })
            if(found !== -1){
              self.tracking[found] = data
              self.tracking.sort()
            }
            else{
              self.tracking.push(data)
            }
          }
        })
      })
    },
    endCall(){
      this.deviceStatus = 9
      this.$store.commit("setCallData", null)
      Device.destroy()
    }
  }
}
</script>