import { endpoints } from './endpoints'
import request from '@/helpers/request'

export function getFixedPanelDevices() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getFixedPanelDevices,
			method: 'get',
		})
			.then(async function (response) {
				resolve(response)
			}).catch(function (error) {
				reject(error)
			})
		})
	}