<template>
  <GmapInfoWindow v-if="componentStatus">
    <CContainer class="top-container" fluid v-show="status">
      <CButtonClose style="font-size: 40px;position: absolute;top: 10px;right: 10px;font-weight: 400;z-index: 9999;" @click="closeInfo(userInfo)"/>
		  <CRow style="width: 620px" v-if="userData === null">
		    <CCol col="12" xl="12" class="text-center">
	        <CSpinner
	          style="width:20px;height:20px;"
	          color="primary"
	        />
	        <p>Cargando información del usuario</p>
		    </CCol>
      </CRow>
		  <CRow style="width: 620px" v-else>
		    <CCol col="12" xl="12" class="d-flex flex-row align-items-stretch">
          <CImg
          	class="mb-1"
	          style="object-fit: cover;width: 100px;height:100px;border-radius: 10px; border: 2px solid #CBCBCB;"
            :src="userData.avatarImg"
          />
          <div class="ml-3 d-flex flex-column">
            <h6 style="font-size: 18px;" class="text-primary mb-0">{{userData.name}}</h6>
            <h6 style="font-size: 14px;font-weight: 400;color: #666666;text-transform: capitalize;">
              Dispositivo {{userInfo.deviceData.device_brand}} {{userInfo.deviceData.device_name}}
            </h6>
            <h6 style="font-size: 14px;font-weight: 400;color: #666666;" class="mb-0">{{userData.email}}</h6>
            <h6 style="font-size: 14px;font-weight: 400;color: #666666;">{{userData.info ? userData.info.telefono:null}}</h6>
  		    </div>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer class="bottom-container scroll-hidden-vertical-sos" style="position: relative;overflow-y: scroll;max-height: 250px;" fluid v-show="status">
      <CRow>
		    <CCol col="12" xl="12">
          <div class="custom-title-info-window">
            <h6 style="font-size: 18px;" class="text-primary m-0">Acciones</h6>
            <hr/>
          </div>
        </CCol>
      </CRow>
      <CRow class="my-2" style="width: 620px" :gutters="false">
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall(userInfo, false, true)"
          >
            <CIcon name="silentMonitor" class="icons-svg outline"/> Monitoreo Silencioso
          </CButton>
        </CCol>
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall(userInfo)"
          >
            <CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
          </CButton>
        </CCol>
        <CCol col="4" xl="4" class="px-1">
          <CButtonGroup v-show="tracking">
            <CButton              
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="startSoloTracking()"
            >
              <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/> Visualizar Ubicación
            </CButton>
            <CButton
              :disabled="deactivatingTracking"
              v-c-tooltip="'Desactivar Tracking'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="deactivateTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <div v-if="!deactivatingTracking">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.2rem!important;height: 1.2rem!important;"/>
                <font-awesome-icon icon="slash" style="font-size: 12px;position: absolute;left: 0;top: 0;bottom: 0;right: 0;margin: auto;" />
              </div>
              <div v-else>
                <CSpinner style="width:20px;height:20px;" color="primary" />
              </div>
            </CButton>
          </CButtonGroup>
          <CButtonGroup v-show="!tracking">
            <CButton
              :disabled="activatingTracking"
              color="primary"
              class="w-100 custom-button-info-content d-flex flex-column justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="activateTracking()"
            >
              <div v-if="!activatingTracking" class="w-100 d-flex justify-content-around align-items-center">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/>
                <p class="m-0">Activar Tracking</p>
              </div>
              <div v-else class="w-100 d-flex justify-content-around align-items-center">
                <CSpinner style="width:20px;height:20px;" color="primary" />
                <p class="m-0">Activando Tracking</p>
              </div>
              <p class="m-0" style="font-size: 10px;">{{trackingActivationMessage}}</p>
            </CButton>
            <CButton
              v-c-tooltip="'Consultar histórico'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="startSoloTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <font-awesome-icon icon="history" style="font-size: 20px;" />
            </CButton>
          </CButtonGroup>
        </CCol>
      </CRow>
      <CRow class="my-2" style="width: 620px" :gutters="false">
        <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="$store.state.toCallData ? true:false"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="call(userData.info.telefono, userData.name)"
          >
            <CIcon name="call" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> Llamar por Teléfono
          </CButton>
        </CCol>
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-3"
            :class="this.showNoti ? 'text-white':''"
            :variant="this.showNoti ? '':'outline'"
            style="flex: 1;"
            @click="() => this.showNoti = !this.showNoti"
          >
            <CIcon :name="this.showNoti ? 'cilX':'notification'" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> {{this.showNoti ? 'Cancelar':'Crear'}} Notificación
          </CButton>
		    </CCol>
		  </CRow>
      <CRow class="mt-2 pt-2" style="border-top: 1px solid #f2f2f2" v-show="showNoti">
        <CCol col="12" xl="12" class="text-center">
          <CInput
            placeholder="Título"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionTitle'
          />
          <CInput
            placeholder="Descripción"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionDescripcion'
          />
          <CAlert v-for="(toast,index) in toasts" :key="index" :color="toast.color" closeButton :show.sync="currentAlertCounter">
            {{toast.message}}
          </CAlert>
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="success"
            class="w-100 custom-button-info-content success d-flex justify-content-around align-items-center py-2"
            style="flex: 1;"
            @click="() => this.sendNotification()"
          >
            <CIcon name="send" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> Enviar Notificación
          </CButton>
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
        </CCol>
      </CRow>
   	</CContainer>
  </GmapInfoWindow>
</template>

<script>
import {getDetails, sendCustomNotification} from '../../../api/user'
import {activateTrackingRemote} from '../../../api/tracking'
import {sendSMS} from '../../../api/calls'
import {createVideoCallRoom,initVideoCallWithDevice} from '../../../api/atenciones'
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'ContentInfoWindow',
  props: ['userInfo', 'componentStatus', 'closeInfo'],
  data(){
    return {
      cuadrante: this.$store.state.userData.info.cuadrante_id,
    	userData: null,
    	status: true,
    	tracking: false,
      showNoti: false,
      notificacionTitle: '',
      notificacionDescripcion: '',
      toasts: [],
      currentAlertCounter: 4,
      trackingActivationMessage: '',
      activatingTracking: false,
      deactivatingTracking: false
    }
  },
  mounted(){
  	this.getUserInfo(this.userInfo.id_usuario)
  	this.checkUserTracking()
  },
  methods: {
    activateTracking(){
      const self = this
      const data = {
        device_id: this.userInfo.deviceID,
        trackingStatus: 1
      }
      console.log("data", data)
      this.activatingTracking = true
      this.trackingActivationMessage = 'Activando el tracking en el dispositivo.'
      activateTrackingRemote(data)
        .then(function(resp){
          console.log("datae", resp)
          self.trackingActivationMessage = 'Esperando notificación de activación.'
          const path = 'SOSclick/tracking/' + self.cuadrante + '/' + self.userInfo.id_usuario + '/' + self.userInfo.deviceID
          firebase.database(self.$store.state.trackingDB).ref(path).on("child_added", function(datos){
            if( datos.val() ){
              self.trackingActivationMessage = 'El tracking ha sido activado en el dispositivo, ahora podrás visualizar su posición en tiempo real.'
              setTimeout(function() {
                self.tracking = true
              }, 3000);
            }
            firebase.database(self.$store.state.trackingDB).ref(path).off("child_added")
            setTimeout(function() {
              self.activatingTracking = false
              self.trackingActivationMessage = ''
            }, 4000);
          })
        })
        .catch(function(error){
          self.trackingActivationMessage = 'Hubo un error de red al enviar la solicitud, por favor inténtalo nuevamente.'
          setTimeout(function() {
            self.activatingTracking = false
            self.trackingActivationMessage = ''
          }, 3000);
        })

    },
    deactivateTracking(){
      const self = this
      const data = {
        device_id: this.userInfo.deviceID,
        trackingStatus: 2
      }
      this.deactivatingTracking = true
      const path = 'SOSclick/tracking/' + self.cuadrante + '/' + self.userInfo.id_usuario + '/' + self.userInfo.deviceID
      firebase.database(self.$store.state.trackingDB).ref(path).on("child_removed", function(datos) {
        firebase.database(self.$store.state.trackingDB).ref(path).off("child_removed")
        self.tracking = false
        self.deactivatingTracking = false
      })
      activateTrackingRemote(data)
        .then(function(resp){
          console.log("Enviado")
        })
        .catch(function(error){
          console.log(error)
          firebase.database(self.$store.state.trackingDB).ref(path).off("child_removed")
          self.deactivatingTracking = false
        })
    },
    sendNotification(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(this.notificacionTitle === '' || this.notificacionDescripcion === ''){
        self.toasts.push({
          title: 'Error',
          message: 'Por favor completa todos los campos antes de enviar la notificación.',
          color: 'warning'
        })
      }
      else{
        sendCustomNotification(this.userInfo.deviceID, this.notificacionTitle, this.notificacionDescripcion)
          .then(function(resp){
            self.toasts.push({
              title: 'Notificación enviada',
              message: 'Se ha enviado la notificación al usuario.',
              color: 'success'
            })
            setTimeout(function() {
              self.showNoti = false
            }, 3000);
          })
          .catch(function(error){
            console.log(error)
            self.toasts.push({
              title: 'Error',
              message: 'Hubo un error al enviar la notificación, por favor inténtalo nuevamente.',
              color: 'danger'
            })
          })
      }
    },
  	checkUserTracking(){
  		const self = this
      const path = 'SOSclick/tracking/' + this.cuadrante + '/' + this.userInfo.id_usuario + '/' + this.userInfo.deviceID
      firebase.database(this.$store.state.trackingDB).ref(path).on("value", function(data){
      	if( data.val() ){
      		self.tracking = true
      	}
      })
  	},
  	startSoloTracking(){
  		if(this.userData && this.userInfo){
      	this.$store.commit("setSoloTrackingUser", this.userInfo)
      }
  	},
  	async getUserInfo(userID){
    	const user = await getDetails(userID)  		
    	this.userData = user.user
      this.$store.commit("setContentInfoUser", this.userData)
  	},
  	async startVideoCall(data, fullView = true, hideVideo = false){
      this.$store.commit("setVideoCallData", {
        status: true,
        alertID: null,
        geoUserAlert: null,
        fullView,
        deviceID: data.deviceID,
        calledUserID: data.id_usuario,
        socketUrl: null,
        socketRoom: null,
        videocallID: null,
        hideVideo,
        silent: true
      })
  	},
    call(phoneToCall, name){
      const self = this
      const callData = {
        phoneToCall,
        name
      }
      this.$store.commit("setCallData", callData)
    },
    sendUserSMS(toPhone, user){
      const self = this
      toPhone = '+' + toPhone
      sendSMS({toPhone, user})
        .then(function(resp){
          self.toasts.push({
            title: 'SMS enviado',
            message: 'Se ha enviado el sms al teléfono del usuario.'
          })
        })
        .catch(function(error){
          console.log(error)
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al enviar el SMS, por favor inténtalo nuevamente.'
          })
        })
    },
  }
}
</script>
<style>
  button.gm-ui-hover-effect{display:none!important;}
  .gm-style-iw.gm-style-iw-c{padding: 0;background: #F7F7F7;}
  .gm-style-iw.gm-style-iw-c .gm-style-iw-d{overflow: hidden!important;}
</style>