<template>
	<div class="alert-preview-container" v-if="alerts && alerts.length > 0">
<!-- 	<div class="alert-preview-container" v-if="alerts"> -->
		<div style="height: 200px; margin-top: 20px;" v-if="opened">
<!-- 				<CCol col="3" sm="4" xl="3" v-for="(m, index) in alerts" :key="index">
					<VideoCallRoomMultiple
						v-if="m.data.videocall && m.data.videocall.socket_url && m.data.videocall.socket_room && !$store.state.videocallSoloConnected"
						:videoCallData="m.data"
						style="width: 100px; height: 100px;"
					/>
				</CCol> -->
<!-- 					<VueSlickCarousel v-bind="settings">
				<div style="margin: 0 auto;display:flex; justify-content: center; align-items:center; gap: 8px;height: 100px;" v-for="(m, index) in data" :key="index">
					<p style="font-size: 32px;">
					{{m}}
					</p>
				</div>
					</VueSlickCarousel> -->
					<VueSlickCarousel v-bind="settings">
					<VideoCallRoomMultiple
						v-if="m.data.videocall && m.data.videocall.socket_url && m.data.videocall.socket_room && !$store.state.videocallSoloConnected"
						:videoCallData="m.data"
						style="width: 100px; height: 100px;"
					/>
					</VueSlickCarousel>
		</div>
		<button type="button" class="btn btn-pill btn-primary btn-alert-preview-handler"
			@click="opened = !opened">
			<font-awesome-icon v-if="opened" icon="chevron-down"/>
			<div v-else class="tada animated infinite">
				<font-awesome-icon icon="bell"/>
			</div>
		</button>
	</div>
</template>

<script>
	import VideoCallRoomMultiple from '@/views/videocall/VideoCallRoomMultiple'
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'CentralPreviewVideoAlert',
  props: ['alerts'],
	components: {VideoCallRoomMultiple, VueSlickCarousel},
  data(){
    return {
			opened: false,
/* 			data: [1,2,3,4,5,6], */
			settings:{
  "dots": true,
  "infinite": false,
  "speed": 500,
  "slidesToShow": 4,
  "slidesToScroll": 4,
  "initialSlide": 0,
  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "infinite": true,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "initialSlide": 2
      }
    },
    {
      "breakpoint": 480,
      "settings": {
        "slidesToShow": 1,
        "slidesToScroll": 1
      }
    }
  ]
}
    }
  },
  methods: {
  }
}
</script>
<style scoped>
	.alert-preview-container{
		position: absolute;
		bottom: 0;
		width: 80%;
		min-height: 30px;
		left: 0;
		right: 0;
		margin: auto;
		background: white;
		border: 2px solid grey;
		border-bottom: 0;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.btn-alert-preview-handler{
		position: absolute;
		top: -20px;
		left: 0;
		right: 0;
		margin: auto;
		width: 40px;
		height: 40px;
	}
	@keyframes tada {
		from {
			transform: scale3d(1, 1, 1);
		}

		10%,
		20% {
			transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
		}

		30%,
		50%,
		70%,
		90% {
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		}

		40%,
		60%,
		80% {
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		}

		to {
			transform: scale3d(1, 1, 1);
		}
	}

	.animated {
		animation-duration: 1s;
		animation-fill-mode: both;
	}
	.animated.infinite {
		animation-iteration-count: infinite;
	}
	.tada {
		animation-name: tada;
	}
</style>